<script lang="ts" setup>
import type { LottiePlayer } from 'lottie-web'
import { storeToRefs } from 'pinia'
import { useRoot } from '~/store'
import type { LordIconSource, LordIconTrigger } from '~/types/assets'

const { src, revert, trigger, delay, size, minimal, loading } = defineProps<{
  src: LordIconSource
  revert?: boolean
  trigger?: LordIconTrigger
  delay?: number
  size?: number | string
  minimal?: boolean
  loading?: string
}>()

const { theme } = storeToRefs(useRoot())

const colors = computed(() => {
  if (minimal) {
    return 'primary:#000000,secondary:#000000'
  } else {
    return `primary:${revert ? theme.value?.hexPrimary : theme.value?.hexSecondary},secondary:${
      revert ? theme.value?.hexSecondary : theme.value?.hexPrimary
    }`
  }
})

const attributes = reactive({
  src: `/api/lord-icon?icon=${src}`,
  trigger,
  style:
    typeof size === 'number'
      ? {
          height: size ? `${size}px` : '100%',
          width: size ? `${size}px` : '100%',
        }
      : {},
  class: typeof size === 'string' ? size : undefined,
  delay,
  colors,
  loading: loading || false,
})

if (process.env.VITEST !== 'true' && import.meta.browser) {
  const { defineElement } = await import('lord-icon-element')
  const lottie = (await import('lottie-web')) as unknown as LottiePlayer
  defineElement(lottie.loadAnimation)
}
</script>

<template lang="pug">
lord-icon.flex-shrink-0(v-bind="attributes")
</template>
